<template>
    <div class="login_div">
                <!-- <h3 class="logindialog_name">大创综合管理平台</h3> -->
                <el-form :model="loginForm" :rules="rules" ref="loginForm" class="demo-ruleForm" v-show="Exount==false">
                    <!-- <el-form-item prop="radio" class="aaa">
                        <el-radio-group v-model="loginForm.radio">
                            <el-radio :label="1">学生</el-radio>
                            <el-radio :label="2">指导教师</el-radio>
                            <el-radio :label="3">管理员</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                    <el-form-item prop="phoneNumber">
                        <el-input v-model="loginForm.phoneNumber" :placeholder="istrue==1?'账号':'手机号'" @keyup.enter.native="enterLogin($event)"></el-input>
                    </el-form-item>
                    <el-form-item prop="code" style="margin-bottom:0px">
                        <el-input v-model="loginForm.password" type="password" placeholder="密码" show-password @focus="focus" v-show="istrue==1" @keyup.enter.native="enterLogin($event)"></el-input>
                        <el-input v-model="loginForm.code"  placeholder="短信验证码" @focus="focus" v-show="istrue==2" @keyup.enter.native="enterLogin($event)">
                            <template slot="append" >
                                <span class="append_span" :class="disabled==true?'form_disabled':''" :disabled='disabled' @click="sendcode()">{{Vercode}}</span>
                            </template>
                        </el-input>
                    </el-form-item>
                    <p class="login_p clearfix">
                        <span class="span_left">{{msg}}</span>
                    </p> 
                </el-form>
                <!-- 注册账号 -->
                <el-form :model="Form" :rules="rules" ref="Form" class="demo-ruleForm" v-show="Exount==true">
                    <el-form-item prop="phoneNumber">
                        <el-input v-model="Form.phoneNumber" placeholder="手机号" @focus="focus" @keyup.enter.native="enterLogin($event)"></el-input>
                    </el-form-item>
                    <el-form-item prop="code">
                        <el-input v-model="Form.code"  placeholder="短信验证码" @focus="focus"  @keyup.enter.native="enterLogin($event)">
                        <template slot="append" >
                            <span class="append_span" :class="disabled==true?'form_disabled':''" :disabled='disabled' @click="sendcode()">{{Vercodes}}</span>
                        </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password" style="margin-bottom:0px">
                        <el-input v-model="Form.password" type="password" placeholder="密码" show-password @focus="focus" @keyup.enter.native="enterLogin($event)"></el-input>
                    </el-form-item>
                    <p class="login_p clearfix">
                        <span class="span_left">{{msgs}}</span>
                    </p> 
                </el-form>
                <div class="login_button" v-show="Exount==false">
                    <el-button  type="primary" size="medium" @click="submitForm('ruleForm')">登 录</el-button>
                </div> 
                <div class="login_button" v-show="Exount==true">
                    <el-button  type="primary" size="medium" @click="regist('Form')">注 册</el-button>
                </div> 
                <!-- <p class="Userment">注册或点击登录代表您同意<span> 《用户服务协议》</span></p> -->
                <div class="diff_login clearfix">
                    <span class="diff_left" v-show="istrue==2 && Exount==false" @click="cation(1)">账号密码登录</span>
                    <span class="diff_left" v-show="istrue==1 && Exount==false" @click="cation(2)">验证码登录</span>
                    <span class="diff_right" v-show="loginForm.radio==1 && Exount==false">
                        没有账号 <span @click="registered(true)">立即注册</span>
                    </span>
                    <span class="diff_right" v-show="loginForm.radio==1 && Exount==true">
                        已有账号 <span @click="registered(false)">立即登录</span>
                    </span>
                </div>
            </div>
</template>
<script>
export default {
    props:['Exount'],
    data () {
        return {
            li_login:1,
            loginForm:{
                radio:1,
            },
            Form:{},
            // Exount:false,
            rules:{},
            istrue:1,
            allactive:'',
            msg:'',
            msgs:'',
            Vercode:'获取验证码',
            Vercodes:'获取验证码',
            disabled:false,
        }
    },
    methods: {
        cation(val){
            this.istrue=val
            this.msg = ''
        },
        // 获取焦点
        focus(){
            this.msg = ''
            this.msgs = ''
        },
        //获取验证码
        sendcode(){
            // 登录获取验证码
            if(this.Exount==false){
                if(this.disabled==false){
                    if(!this.loginForm.phoneNumber){
                        this.msg='请输入手机号码'
                    }else if(!/^1[3456789]\d{9}$/.test(this.loginForm.phoneNumber)){
                        this.msg='请输入正确的手机号码'
                    }else{
                        // 登录获取验证码
                        this.axios.duanxin({
                            phoneNumber:this.loginForm.phoneNumber
                        }).then(res=>{
                            if(res.data.code==200){
                                this.time = 60
                                this.timer()
                            }else{
                                this.msg = res.data.msg
                            }
                        }).catch(err=>{

                        })
                        
                    }
                }
            }else{
                // 注册获取验证码
                if(!this.Form.phoneNumber){
                    this.msgs='请输入手机号码'
                }else if(!/^1[3456789]\d{9}$/.test(this.Form.phoneNumber)){
                    this.msgs='请输入正确的手机号码'
                }else{
                    // 注册获取验证码
                    this.axios.adminzhuce({
                        phoneNumber:this.Form.phoneNumber
                    }).then(res=>{
                        if(res.data.code==200){
                            this.times = 60
                            this.timer()
                        }else{
                            this.msgs = res.data.msg
                        }
                    }).catch(err=>{

                    })
                }
            }
            
        },
        //注册
        regist(){
            if(!this.Form.phoneNumber){
                this.msgs='请输入手机号码'
            }else if(!/^1[3456789]\d{9}$/.test(this.Form.phoneNumber)){
                this.msgs='请输入正确的手机号码'
            }else if(!this.Form.code){
                this.msgs='请输入验证码'
            }else if(!this.Form.password){
                this.msgs='请输入密码'
            }else if(!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,}$/.test(this.Form.password)){
                this.msgs = "密码为8-16位字母、数字或符号2种组合"
            }else{
                let currentUrl=window.location.href
                this.allactive = currentUrl.split('/')[2]
                this.axios.adminregist({
                    domain:this.allactive,
                    code:this.Form.code,
                    phoneNumber:this.Form.phoneNumber,
                    password:this.Form.password
                }).then(res=>{
                    if(res.data.code==200){
                        this.$emit('closepop',false);
                    }else{
                        this.msgs = res.data.msg
                    }
                }).catch(err=>{

                })
            }
            
        },
        timer(){
            if(this.Exount==false){
                if(this.time>0){
                    this.disabled = true
                    this.time--
                    this.Vercode = "重新获取("+this.time+")"
                    setTimeout(this.timer,1000)
                }else{
                    this.time = 0
                    this.Vercode = "发送验证码"
                    this.disabled = false
                }
            }else{
                if(this.times>0){
                    this.disabled = true
                    this.times--
                    this.Vercodes = "重新获取("+this.times+")"
                    setTimeout(this.timer,1000)
                }else{
                    this.times = 0
                    this.Vercodes = "发送验证码"
                    this.disabled = false
                }
            }
            
        },
        registered(val){
            // this.Exount = val
            this.$emit('closepop',val);
        },
        enterLogin(e){
            if(e.which == 13 || e.charCode == 13 || e.keyCode == 13){
                this.submitForm()
            }
        },
        // 登录
        submitForm(){
                if(!this.loginForm.phoneNumber){
                    if(this.istrue==1){
                        this.msg='请输入账号'
                    }else{
                        this.msg='请输入手机号'
                    }
                }else if(!/^\d{11}$/.test(this.loginForm.phoneNumber)&&this.istrue==1){
                    this.msg='请输入正确的账号'
                }else if(!/^1[3456789]\d{9}$/.test(this.loginForm.phoneNumber)&&this.istrue==2){
                    this.msg='请输入正确的手机号'
                }else if(this.istrue==1&&!this.loginForm.password){
                    this.msg='请输入密码'
                }else if(this.istrue==2 && !this.loginForm.code){
                    this.msg='请输入验证码'
                }else{
                    if(this.istrue==1){
                        this.axios.adminlogin({
                            phoneNumber:this.loginForm.phoneNumber,
                            password:this.loginForm.password,
                            type:1
                        }).then(res=>{
                            if(res.data.code==200){
                                this.$store.dispatch('token', res.data.token)
                                this.$store.dispatch('uid', res.data.user.adId)
                                this.$store.dispatch('schId', res.data.user.schoolId)
                                this.$store.dispatch('itemId', res.data.itemId)
                                this.$store.dispatch('academyId',res.data.user.academyId)
                                this.$store.dispatch('schName',res.data.user.deptName)
                                console.log(res.data.batch)
                                if(res.data.batch!=null && res.data.batch!=''){
                                    this.$store.dispatch('startTime',res.data.batch.startTime)
                                    this.$store.dispatch('endTime',res.data.batch.endTime)
                                }
                                
                                if(res.data.user.type==0){
                                    this.$router.push({name:'admin'})
                                }else if(res.data.user.type==1){
                                    this.$store.dispatch('depName', res.data.user.yname)
                                    this.$router.push({name:'Seconadmin'})
                                }else if(res.data.user.type==2){
                                    this.$router.push({name:'teacher'})
                                    this.$store.dispatch('teaId', res.data.user.tId)
                                }else if(res.data.user.type==3){
                                    if(res.data.itemId===null || res.data.itemId===''){
                                        this.$router.push({name:'Projectapplication'})
                                    }else{
                                        this.$router.push({name:'Projectview'})
                                    } 
                                }else if(res.data.user.type==9){
                                    this.$router.push({name:'review'})
                                }
                            }else{
                                this.msg = res.data.msg
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.adminlogin({
                            phoneNumber:this.loginForm.phoneNumber,
                            code:this.loginForm.code,
                            type:2
                        }).then(res=>{
                            if(res.data.code==200){
                                this.$store.dispatch('token', res.data.token)
                                this.$store.dispatch('uid', res.data.user.adId)
                                this.$store.dispatch('schId', res.data.user.schoolId)
                                this.$store.dispatch('itemId', res.data.itemId)
                                this.$store.dispatch('academyId',res.data.user.academyId)
                                this.$store.dispatch('schName',res.data.user.deptName)
                                if(res.data.batch!=null && res.data.batch!=''){
                                    this.$store.dispatch('startTime',res.data.batch.startTime)
                                    this.$store.dispatch('endTime',res.data.batch.endTime)
                                }
                                if(res.data.user.type==0){
                                    this.$router.push({name:'admin'})
                                }else if(res.data.user.type==1){
                                    this.$store.dispatch('depName', res.data.user.yname)
                                    this.$router.push({name:'Seconadmin'})
                                }else if(res.data.user.type==2){
                                    this.$router.push({name:'teacher'})
                                    this.$store.dispatch('teaId', res.data.user.tId)
                                }else if(res.data.user.type==3){
                                    setTimeout(() => {
                                        this.$router.push({name:'student'})
                                    }, 1000);
                                }else if(res.data.user.type==9){
                                    this.$router.push({name:'review'})
                                }
                            }else{
                                this.msg = res.data.msg
                            }
                        }).catch(err=>{

                        })
                    }
                }
        },
    },
    mounted() {
                
    },
}
</script>
<style scoped>
@import './../assets/css/login/login.css';
</style>