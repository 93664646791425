<template>
    <div class="login_div">
                <!-- <h3 class="logindialog_name">大创综合管理平台</h3>s -->
                <el-form :model="loginForm" :rules="rules" ref="loginForm" class="demo-ruleForm" v-show="Exount==false">
                    <!-- <el-form-item prop="radio" class="aaa">
                        <el-radio-group v-model="loginForm.radio">
                            <el-radio :label="1">学生</el-radio>
                            <el-radio :label="2">指导教师</el-radio>
                            <el-radio :label="3">管理员</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                    <el-form-item prop="account">
                        <el-input v-model="loginForm.account" placeholder="手机号" @keyup.enter.native="enterLogin($event)"></el-input>
                    </el-form-item>
                    <el-form-item prop="password" style="margin-bottom:0px">
                        <el-input v-model="loginForm.password" type="password" placeholder="密码" show-password @focus="focus" v-show="istrue==1" @keyup.enter.native="enterLogin($event)"></el-input>
                        <el-input v-model="loginForm.captcha"  placeholder="短信验证码" @focus="focus" v-show="istrue==2" @keyup.enter.native="enterLogin($event)">
                        <template slot="append" >
                            <span class="append_span" :class="disabled==true?'form_disabled':''" :disabled='disabled' @click="sendcode()">{{Vercode}}</span>
                        </template>
                        </el-input>
                    </el-form-item>
                    <p class="login_p clearfix">
                        <span class="span_left">{{msg}}</span>
                    </p> 
                </el-form>
                <!-- 注册账号 -->
                <el-form :model="Form" :rules="rules" ref="Form" class="demo-ruleForm" v-show="Exount==true">
                    <el-form-item prop="account">
                        <el-input v-model="Form.account" placeholder="手机号" @keyup.enter.native="enterLogin($event)"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="Form.captcha"  placeholder="短信验证码" @focus="focus"  @keyup.enter.native="enterLogin($event)">
                        <template slot="append" >
                            <span class="append_span" :class="disabled==true?'form_disabled':''" :disabled='disabled' @click="sendcode()">{{Vercode}}</span>
                        </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password" style="margin-bottom:0px">
                        <el-input v-model="Form.password" type="password" placeholder="密码" show-password @focus="focus" @keyup.enter.native="enterLogin($event)"></el-input>
                    </el-form-item>
                    <p class="login_p clearfix">
                        <span class="span_left">{{msg}}</span>
                    </p> 
                </el-form>
                <div class="login_button" v-show="Exount==false">
                    <el-button  type="primary" size="medium" @click="submitForm('ruleForm')">登 录</el-button>
                </div> 
                <div class="login_button" v-show="Exount==true">
                    <el-button  type="primary" size="medium" @click="submitForm('ruleForm')">注 册</el-button>
                </div> 
                <p class="Userment">注册或点击登录代表您同意<span> 《用户服务协议》</span></p>
                <div class="diff_login clearfix">
                    <span class="diff_left" v-show="istrue==2 && Exount==false" @click="cation(1)">账号密码登录</span>
                    <span class="diff_left" v-show="istrue==1 && Exount==false" @click="cation(2)">验证码登录</span>
                    <span class="diff_right" v-show="loginForm.radio==1 && Exount==false">
                        没有账号 <span @click="registered(true)">立即注册</span>
                    </span>
                    <span class="diff_right" v-show="loginForm.radio==1 && Exount==true">
                        已有账号 <span @click="registered(false)">立即登录</span>
                    </span>
                </div>
            </div>
</template>
<script>
export default {
    props:['Exount'],
    data () {
        return {
            li_login:1,
            loginForm:{
                radio:1,
            },
            Form:{},
            // Exount:false,
            rules:{},
            istrue:1,
            msg:'',
            Vercode:'获取验证码',
            disabled:false,
        }
    },
    methods: {
        // 登录平台切换
        cho_li(val){
            this.li_login = val
        },
        cation(val){
            this.istrue=val
        },
        // 获取焦点
        focus(){
            this.msg = ''
        },
        //获取验证码
        sendcode(){
            if(this.disabled==false){
                if(!this.loginForm.account){
                    this.msg='请输入手机号码'
                }else if(!/^1[3456789]\d{9}$/.test(this.loginForm.account)){
                    this.msg='请输入正确的手机号码'
                }else{
                    // 登录获取验证码
                    this.time = 60
                    this.timer()
                }
                
            }
        },
        timer(){
            if(this.time>0){
                this.disabled = true
                this.time--
                this.Vercode = "重新获取("+this.time+")"
                setTimeout(this.timer,1000)
            }else{
                this.time = 0
                this.Vercode = "发送验证码"
                this.disabled = false
            }
        },
        registered(val){
            this.$emit('closepop',val);
            // this.Exount = val
        },
        enterLogin(e){
            if(e.which == 13 || e.charCode == 13 || e.keyCode == 13){
                this.submitForm()
            }
        },
        submitForm(){
            if(this.loginForm.radio==1){
                this.$router.push({name:'student'})
            }else if(this.loginForm.radio==2){
                this.$router.push({name:'teacher'})
            }else{
                this.$router.push({name:'admin'})
            }
        },
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../assets/css/login/login.css';
</style>